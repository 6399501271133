import React, { useMemo } from 'react';
import { Button, Spin } from 'antd';
import SuccessAnimationFireworksFish from './SuccessAnimationFireworksFish';
import {
  useCreateNewSubscriptionCheckoutMutation,
  useGetPricesQuery,
} from '../registrationSlice';
import { useGetUserQuery } from '../../users/usersSlice';
import { SUBSCRIPTION_SIGN_UP_ROUTE } from '../../../app/appConstants';
import { SubscriptionLevel } from './PricingCard';
import { SubscriptionLoading } from '../index';

function GiveawayContent(): React.JSX.Element {
  const [createSession, { isLoading: submitting }] =
    useCreateNewSubscriptionCheckoutMutation();
  const { data: userData } = useGetUserQuery();
  const { data: prices, isLoading: loading } = useGetPricesQuery();
  const { proMonthlyPrice } = useMemo(
    () => ({
      proMonthlyPrice: prices?.Pro?.find((p) => p.interval === 'month'),
    }),
    [prices?.Pro]
  );
  const requestCheckoutSessionAndRedirect = () => {
    const successUrl =
      `${window.location.protocol}//${window.location.host}` +
      `${SUBSCRIPTION_SIGN_UP_ROUTE}/pre-success?plan=${encodeURIComponent(
        SubscriptionLevel.Pro
      )}&sessionId={CHECKOUT_SESSION_ID}`;

    const sessionParams = {
      customer_email: userData?.email ?? null,
      price_id: proMonthlyPrice?.id ?? '',
      success_url: successUrl,
      cancel_url: window.location.href,
      is_competition_winner: true,
    };
    createSession(sessionParams)
      .unwrap()
      .then((redirect_url) => {
        if (redirect_url) {
          window.location.href = redirect_url;
        }
      })
      .catch(() => {
        /* Handled by defaultErrorHandler */
      });
  };

  if (loading) {
    return <SubscriptionLoading hideMessage />;
  }

  return (
    <>
      <SuccessAnimationFireworksFish />
      <div className='mx-auto mb-8 text-blue-100 max-[500px]:max-w-[17.5rem]'>
        <p className='mb-9 text-3xl font-black uppercase'>
          You&apos;ve won a <strong className='text-white'>Free Month</strong>{' '}
          of swimbooker+
        </p>
        <p className='mb-9 text-xl font-bold'>
          Click the link below to sign up and access all{' '}
          <strong className='text-white'>PRO+ features</strong> for{' '}
          <strong className='text-white'>FREE!</strong>
        </p>
        <Button
          className='mb-8 h-fit w-full whitespace-normal rounded-md border-none bg-blue-900 p-2.5 text-xl font-bold uppercase text-white'
          onClick={() => {
            if (submitting) {
              return; // Don't submit twice
            }
            requestCheckoutSessionAndRedirect();
          }}
        >
          {submitting ? <Spin /> : 'Claim Prize'}
        </Button>
        <p className='px-4 text-base font-normal leading-[1.3] text-white'>
          <strong>Questions?</strong> A member of our team will be more than
          happy to answer any questions.
        </p>
      </div>
    </>
  );
}

export default GiveawayContent;
