import { useCreateNewSubscriptionCheckoutMutation } from '../registrationSlice';
import { useGetUserQuery } from '../../users/usersSlice';
import { Button, Spin } from 'antd';
import React from 'react';
import { SUBSCRIPTION_SIGN_UP_ROUTE } from '../../../app/appConstants';
import { formatAsPounds } from '../../../utils/payment-utils';

export enum SubscriptionLevel {
  Pro = 'Pro',
  Plus = 'Plus',
  Basic = 'Basic',
}

interface PriceCardProps {
  plan: SubscriptionLevel;
  price?: number;
  interval?: 'month' | 'year';
  fullPrice?: number;
  priceId?: string;
  features?: React.ReactNode[];
  trialDays?: number | null;
}

function PlanTitle({ plan }: { plan: SubscriptionLevel }): React.JSX.Element {
  switch (plan) {
    case SubscriptionLevel.Plus:
      return <>swimbooker+</>;
    case SubscriptionLevel.Pro:
      return (
        <>
          swimbooker+ <span className='font-montserrat font-black'>PRO</span>
        </>
      );
    case SubscriptionLevel.Basic:
      return <>swimbooker Basic</>;
  }
}

export default function PricingCard({
  plan,
  price = 0,
  fullPrice,
  interval,
  priceId,
  features = [],
  trialDays = null,
}: PriceCardProps): React.JSX.Element {
  const [createSession, { isLoading: submitting }] =
    useCreateNewSubscriptionCheckoutMutation();
  const offerTrial = Boolean(trialDays);

  const { data: userData } = useGetUserQuery();

  const requestCheckoutSessionAndRedirect = () => {
    const successUrl =
      `${window.location.protocol}//${window.location.host}` +
      `${SUBSCRIPTION_SIGN_UP_ROUTE}/pre-success?plan=${encodeURIComponent(
        plan
      )}&sessionId={CHECKOUT_SESSION_ID}`;

    const sessionParams = {
      customer_email: userData?.email ?? null,
      price_id: priceId ?? '',
      success_url: successUrl,
      cancel_url: window.location.href,
      request_trial: offerTrial,
    };
    createSession(sessionParams)
      .unwrap()
      .then((redirect_url) => {
        if (redirect_url) {
          window.location.href = redirect_url;
        }
      })
      .catch(() => {
        /* Handled by defaultErrorHandler */
      });
  };

  const featuresList = (
    <ul className='my-2 list-inside list-disc text-sm leading-8'>
      {features.map((feature, i) => (
        <li key={i}>{feature}</li>
      ))}
    </ul>
  );

  let checkoutText;
  if (submitting) {
    checkoutText = <Spin className='text-grey-100' />;
  } else if (offerTrial) {
    checkoutText = `Try ${plan} for free`;
  } else {
    checkoutText = `Choose ${plan}`;
  }
  const checkoutButton = (
    <Button
      className='h-fit w-full whitespace-normal rounded-md border-none bg-blue-500 p-3.5 text-xl font-semibold text-grey-100'
      disabled={!priceId}
      onClick={() => {
        if (submitting) {
          return; // Don't submit twice
        }
        requestCheckoutSessionAndRedirect();
      }}
    >
      {checkoutText}
    </Button>
  );

  let termsText;
  if (offerTrial) {
    termsText = (
      <>
        Free for {trialDays} days followed by {formatAsPounds(price)} per{' '}
        {interval}.
      </>
    );
  } else {
    termsText = (
      <>
        Claim swimbooker+{plan === SubscriptionLevel.Pro ? ' Pro' : ''} for{' '}
        {formatAsPounds(price)} per {interval}.
      </>
    );
  }

  const hasPrice = price > 0;
  const intervalId = 'per-interval-' + plan;

  return (
    <div className='flex max-w-sm shrink grow basis-80 flex-col rounded-md border border-t-[5px] border-grey-600 border-t-blue-500 bg-transparent px-7 py-4 text-start'>
      <div className='flex flex-wrap items-end justify-between text-end'>
        <span className='whitespace-nowrap rounded-md bg-blue-500 px-5 py-1 text-xs font-bold uppercase text-grey-100'>
          50% Discount
        </span>
        <div className='flex grow items-baseline justify-end gap-2.5 leading-none'>
          {Boolean(price && fullPrice && price < fullPrice) && (
            <del className='text-base font-semibold text-grey-400 line-through'>
              {formatAsPounds(fullPrice ?? 0)}
            </del>
          )}
          <span
            className='text-3xl font-bold text-grey-100'
            aria-describedby={hasPrice && interval ? intervalId : undefined}
          >
            {hasPrice ? formatAsPounds(price) : 'FREE'}
          </span>
        </div>
      </div>
      {hasPrice && interval && (
        <div
          className='mr-1 text-end text-xs leading-none text-grey-200'
          id={intervalId}
        >
          per {interval}
        </div>
      )}
      <p className='pt-2 text-2xl font-bold text-grey-100'>
        <PlanTitle plan={plan} />
      </p>

      {Boolean(features?.length) && <div className='my-1'>{featuresList}</div>}
      {checkoutButton}
      {plan !== SubscriptionLevel.Basic && (
        <p className='mt-3.5 text-center text-[11px] font-semibold leading-[1.3] text-grey-400'>
          {termsText} Offer available for new subscribers only.
        </p>
      )}
    </div>
  );
}
