import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { grantCookiesConsent } from '../../features/cookieConsent';
import anglersGridHeader from '../../features/registration/assets/media/anglers-grid-header.png';
import { Divider } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  FAQs,
  FeatureTable,
  PricingPlanSelection,
} from '../../features/registration';
import mastercardMark from '../../features/registration/assets/media/Mastercard.png';
import applePayMark from '../../features/registration/assets/media/Apple_Pay_Mark.png';
import googlePayMark from '../../features/registration/assets/media/Google_Pay_Mark.png';
import visaMark from '../../features/registration/assets/media/Visa.png';
import sandhurst from '../../features/registration/assets/media/offer-badges/sandhurst.jpeg';
import stickyBaits from '../../features/registration/assets/media/offer-badges/baits.png';
import approach from '../../features/registration/assets/media/offer-badges/approach.jpeg';
import elphicks from '../../features/registration/assets/media/offer-badges/elphicks.jpeg';
import carpworks from '../../features/registration/assets/media/offer-badges/carpwork.jpeg';
import wolf from '../../features/registration/assets/media/offer-badges/wolf.jpeg';
import FeatureSlider from '../../features/registration/components/FeatureSlider';
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from '../../app/appConstants';
import {
  useIsFisheryAdminLoggedInQuery,
  useLogoutMutation,
} from '../../features/users/usersSlice';

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef<string>('');

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return null;
}

const offerBadges = [
  {
    img: sandhurst,
    name: 'Sandhurst Lake',
    forceNarrow: true,
  },
  {
    img: elphicks,
    name: 'Elphicks Fisheries',
    forceNarrow: true,
  },
  {
    img: approach,
    name: 'The Approach Fisheries',
  },
  {
    img: stickyBaits,
    name: 'Sticky Baits',
    forceNarrow: true,
  },
  {
    img: carpworks,
    name: 'Carpworks Clothing',
    forceNarrow: true,
  },
  {
    img: wolf,
    name: 'Wolf International',
    forceNarrow: true,
  },
];

export default function SubscriptionFeaturesPage(): React.JSX.Element {
  const [logout] = useLogoutMutation();
  const { data: isLoggedInAsAdmin } = useIsFisheryAdminLoggedInQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    grantCookiesConsent();
  }, []);

  useEffect(() => {
    if (isLoggedInAsAdmin) {
      logout(); // Prevents fishery admin accounts from being associated with subscriptions
    }
  }, [isLoggedInAsAdmin, logout]);

  return (
    <>
      <Helmet>
        <title>swimbooker+ | Subscribe Today!</title>
      </Helmet>
      <ScrollToAnchor />
      <div className='min-h-screen min-w-full bg-grey-900 text-grey-300'>
        <div className='mx-auto min-h-screen overflow-hidden'>
          <header className='mb-12'>
            <img
              src={anglersGridHeader}
              alt='Grid of Swimbooker+ anglers'
              className='-mb-8'
            />
            <div className='flex flex-col items-center gap-[18px] px-6 text-center'>
              <h1 className='flex max-w-sm flex-col gap-[19px] text-white'>
                <span className='text-[28px] font-semibold'>swimbooker+</span>
                <span className='text-3xl font-bold'>
                  The Membership For Anglers
                </span>
              </h1>
              <p className='mb-3 max-w-[330px] text-xl font-semibold'>
                Join today and receive a 50% lifetime discounted rate
              </p>
              <div className='flex w-full max-w-2xl flex-row flex-wrap justify-center gap-4 px-8'>
                <Link
                  to='#plans'
                  className='h-fit w-full min-w-fit max-w-[330px] shrink grow basis-52 rounded-md bg-blue-500 py-3 text-center text-xl font-semibold text-white focus-visible:outline focus-visible:outline-4 focus-visible:outline-blue-300'
                >
                  Join the club
                </Link>
                <Link
                  to='#features'
                  className='h-fit w-full min-w-fit max-w-[330px] shrink grow basis-52 rounded-md border border-grey-200 bg-transparent py-3 text-center text-xl font-semibold text-grey-200 focus-visible:outline focus-visible:outline-4 focus-visible:outline-blue-300'
                >
                  Compare Features
                </Link>
              </div>
              <p className='max-w-[330px] text-xs font-semibold text-grey-400'>
                Monthly & Annual package deals available to new subscribers
                only. Terms and Conditions apply.
              </p>
            </div>
          </header>
          <main className='mx-auto max-w-3xl'>
            <section className='mb-12'>
              <h2 className='mb-4 px-6 text-[22px] font-bold text-grey-200'>
                What is swimbooker+?
              </h2>
              <FeatureSlider />
            </section>
            <section>
              <h2 className='mb-3 px-6 text-xl font-bold text-grey-200'>
                Incredible Lakes. Incredible Offers.
              </h2>
              <p className='px-6 text-base font-medium '>
                Earn credit at lakes worldwide.
              </p>
              <p className='mb-9 px-6 text-base font-medium'>
                Access exclusive perks from industry suppliers.
              </p>
              <div className='mb-1 grid grid-cols-3 items-start gap-8 px-6 max-[360px]:gap-6 max-[319px]:grid-cols-2 sm:grid-cols-6 sm:gap-4'>
                {offerBadges.map(({ img, name, forceNarrow = false }) => (
                  <div
                    key={name}
                    className='flex flex-col items-center justify-center'
                  >
                    <img
                      src={img}
                      alt={name}
                      className='mb-2 aspect-square w-full max-w-[5rem] rounded-full border border-grey-700 bg-white object-scale-down'
                    />
                    <p
                      className={
                        'mx-auto flex max-w-full justify-center text-center text-[15px] font-semibold text-grey-100 ' +
                        (forceNarrow ? 'w-min' : '')
                      }
                    >
                      {name}
                    </p>
                  </div>
                ))}
              </div>
            </section>
            <Divider className='mx-auto my-8 min-w-0 max-w-[290px] border-2 border-grey-600' />
            <section className='mb-12 scroll-mt-6' id='features'>
              <h2 className='mb-3 px-6 text-2xl font-bold text-grey-100'>
                Become part of the club.
              </h2>
              <p className='mb-6 px-6 text-base font-medium'>
                Join swimbooker+ to enjoy a host of angler benefits. Cancel
                anytime.
              </p>
              <FeatureTable />
              <div className='mx-auto mt-6 flex w-full max-w-2xl flex-row justify-center'>
                <Link
                  to='#value'
                  className='h-fit w-full min-w-fit max-w-sm shrink grow basis-52 rounded-md border border-grey-200 bg-transparent px-8 py-3 text-center text-xl font-semibold text-grey-200 focus-visible:outline focus-visible:outline-4 focus-visible:outline-blue-300'
                >
                  Compare Plans
                </Link>
              </div>
            </section>
            <section className='mb-12 scroll-mt-6' id='value'>
              <h2 className='mb-3 px-6 text-2xl font-bold text-grey-100'>
                Incredible value packed in.
              </h2>
              <p className='mb-7 px-6 text-base font-medium'>
                Choose the plan which suits you best. Pay with multiple payment
                methods in a secure way. Cancel anytime.
              </p>
              <div className='w-full px-6'>
                <div className='mx-auto mb-6 flex max-w-sm flex-row flex-wrap'>
                  <img
                    src={applePayMark}
                    alt='Apple Pay Logo'
                    className='w-16'
                  />
                  <img
                    src={googlePayMark}
                    alt='Google Pay Logo'
                    className='w-16'
                  />
                  <img
                    src={mastercardMark}
                    alt='Mastercard Logo'
                    className='w-16'
                  />
                  <img src={visaMark} alt='Visa Logo' className='w-16' />
                </div>
              </div>
              <div className='w-full px-6'>
                <div className='mx-auto max-w-sm rounded-md border-[1px] border-blue-300 px-6  py-4'>
                  <h3 className='mb-2 text-base font-bold uppercase text-grey-100 '>
                    All Plans Include
                  </h3>
                  <ul className='list-inside list-image-checkmark-blue font-medium leading-7'>
                    <li>Book venues in the UK & abroad</li>
                    <li>Submit & manage catch reports</li>
                    <li>Weekly giveaways access</li>
                    <li>InSession | Shop | Perks</li>
                  </ul>
                </div>
              </div>
              <div id='plans'>
                <PricingPlanSelection className='px-6 pt-7' offerTrial={true} />
              </div>
            </section>
            <section className='mx-auto mb-6 max-w-xl'>
              <h2 className='mb-3 px-6 text-center text-2xl font-bold text-grey-100'>
                Questions?
              </h2>
              <p className='mb-6 px-6 text-center text-base font-medium'>
                We&apos;ve got answers.
              </p>
              <FAQs />
            </section>
            <footer className='mb-6 flex flex-col items-center justify-center gap-x-7 gap-y-3 px-6 py-2 text-center text-sm font-medium text-grey-400 sm:flex-row'>
              <p>
                Copyright &copy; SWIMBOOKER LIMITED {new Date().getFullYear()}
              </p>
              <a
                href={TERMS_AND_CONDITIONS_URL}
                target='_blank'
                rel='noopener noreferrer'
                className='underline'
              >
                Terms & Conditions
              </a>
              <a
                href={PRIVACY_POLICY_URL}
                target='_blank'
                rel='noopener noreferrer'
                className='underline'
              >
                Privacy Policy
              </a>
            </footer>
          </main>
        </div>
      </div>
    </>
  );
}
